/** Select menu **/
.blnk-react-autocomplete__control {
  @apply w-full appearance-none rounded-lg border-0 p-3 ring-1 ring-info-400 !important;
}

.blnk-react-autocomplete__control--is-focused {
  @apply shadow-textfield-primary outline-none ring-0 !important;
}

.blnk-react-autocomplete__control--is-disabled {
  @apply pointer-events-none cursor-default bg-info-100 ring-info-200 !important;
}

.blnk-react-autocomplete__control--is-disabled
  .blnk-react-autocomplete__dropdown-indicator {
  @apply text-info-400 !important;
}

/** Container around selected value **/
.blnk-react-autocomplete__value-container {
  @apply cursor-text p-0 !important;
}

/** Placeholder **/
.blnk-react-autocomplete__placeholder {
  @apply m-0 text-info-300 !important;
}

/** Separator - By default, a separator is present left to the arrow, we need to remove it **/
.blnk-react-autocomplete__indicator-separator {
  @apply hidden;
}

/** Container around dropdown arrow **/
.blnk-react-autocomplete__dropdown-indicator {
  @apply text-info-600 !important;
}

.blnk-react-autocomplete__control--is-focused
  .blnk-react-autocomplete__dropdown-indicator {
  @apply text-primary !important;
}

/** There is a div without identifying class in `blnk-react-autocomplete__value-container`,
it's the only way to remove its spacing **/
.blnk-react-autocomplete__value-container > div {
  @apply m-0 p-0 !important;
}

/** Option in dropdown menu **/
.blnk-react-autocomplete__option {
  @apply font-bold text-info-600 !important;
  background: inherit !important;
}

.blnk-react-autocomplete__option--is-focused {
  @apply bg-primary-200 !important;
}

/** Selected Value  **/
.blnk-react-autocomplete__single-value {
  @apply font-bold text-info-600 !important;
}

.blnk-react-autocomplete__single-value--is-disabled {
  @apply text-info-400 !important;
}

/** Clear icon **/
.blnk-react-autocomplete__clear-indicator {
  @apply py-0 pr-2 !important;
}

/** Input element **/
.blnk-react-autocomplete__input {
  /** Removes outline added by form plugin **/
  @apply ring-0 !important;
}
