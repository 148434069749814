@tailwind base;

/* Write your own custom base styles here */

input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
}

/* Start purging... */
@tailwind components;

html,
body {
  @apply font-default text-info-600;
}

p {
  @apply text-base;
}

button {
  @apply focus:outline-none;
}

/* Start purging... */
@tailwind utilities;

/* Your own custom utilities */
