.rc-slider .rc-slider-rail {
  @apply h-1.5 bg-gray-200;
}

.rc-slider .rc-slider-track {
  @apply h-1.5 bg-primary;
}

.rc-slider-disabled .rc-slider-track {
  @apply hidden;
}

.rc-slider-disabled {
  @apply bg-white !important;
}

.rc-slider .rc-slider-handle {
  @apply -top-1 h-9 w-9 border-6 border-primary bg-white;
}

.rc-slider .rc-slider-handle:active {
  @apply border-primary-600;
}

.rc-slider .rc-slider-handle:hover {
  @apply border-primary;
}

.rc-slider-disabled .rc-slider-handle {
  @apply border-info-300;
}

.rc-slider .rc-slider-handle:focus {
  @apply border-primary;
}

.rc-slider-handle-click-focused:focus {
  @apply border-primary;
}
