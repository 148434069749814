/** Select menu **/
.blnk-react-select__control {
  @apply w-full cursor-pointer appearance-none rounded-lg border-0 px-3 py-4 ring-1 ring-info-400 !important;
}

.transaction-category-select__control {
  @apply w-full cursor-pointer appearance-none rounded-lg border-0 p-4 ring-1 ring-info-100 !important;
}

.edit_transaction-category-select__control {
  @apply w-full cursor-pointer appearance-none rounded-lg border-0 p-4 ring-1 ring-info-400 !important;
}

.blnk-react-select__control--is-focused,
[class*='transaction-category-select__control--is-focused'] {
  @apply !shadow-textfield-primary outline-none !ring-0;
}

.blnk-react-select__control--is-disabled,
[class*='transaction-category-select__control--is-disabled'] {
  @apply bg-white !important;
}

/** Container around selected value **/
.blnk-react-select__value-container,
[class*='transaction-category-select__value-container'] {
  @apply p-0 !important;
}

/** Placeholder **/
.blnk-react-select__placeholder,
[class*='transaction-category-select__placeholder'] {
  @apply m-0 !important;
}

/** Separator - By default, a separator is present left to the arrow, we need to remove it **/
.blnk-react-select__indicator-separator,
[class*='transaction-category-select__indicator-separator'] {
  @apply hidden;
}

/** Container around dropdown arrow **/
.blnk-react-select__dropdown-indicator,
[class*='transaction-category-select__dropdown-indicator'] {
  @apply m-0 p-0 text-info-600 !important;
}

/** Dropdown indicator icon **/
.blnk-react-select__dropdown-indicator > svg,
[class*='transaction-category-select__dropdown-indicator'] > svg {
  @apply h-4 w-4 !important;
}

/** There is a div without identifying class in `blnk-react-select__value-container`,
it's the only way to remove its spacing **/
.blnk-react-select__value-container > div,
[class*='transaction-category-select__value-container'] > div {
  @apply m-0 p-0 !important;
}

/** Option in dropdown menu **/
.blnk-react-select__option,
[class*='transaction-category-select__option'] {
  @apply cursor-pointer font-bold text-info-600 !important;

  background: inherit !important;
}

.blnk-react-select__option--is-focused,
[class*='transaction-category-select__option--is-focused'] {
  @apply bg-info-90 !important;
}

/** Selected Value  **/
.blnk-react-select__single-value,
[class*='transaction-category-select__single-value'] {
  @apply font-bold text-info-600 !important;
}

/** CLear icon **/
.blnk-react-select__clear-indicator,
[class*='transaction-category-select__clear-indicator'] {
  @apply py-0 pr-2 !important;
}

/** Input element **/
.blnk-react-select__input,
[class*='transaction-category-select__input'] {
  /** Removes outline added by form plugin **/
  @apply ring-0 !important;
}
