.control-dots {
  @apply m-0 !important;
}

.control-dots .dot {
  @apply bg-info-100 opacity-100 shadow-none !important;
}

.control-dots .dot.selected {
  @apply bg-primary !important;
}
