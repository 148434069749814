.react-datepicker {
  @apply !rounded-2xl !border-info-100 shadow-md;
}

.react-datepicker-popper {
  @apply !z-40;
}

.react-datepicker__header {
  @apply w-[308px] !border-none !bg-transparent !p-0 !px-3;
}

.react-datepicker__day-names {
  @apply !m-0 pb-1 pt-2;
}

.react-datepicker__day-name {
  @apply !m-0 !h-10 !w-10 font-default text-base font-bold !leading-10 !text-info-600;
}

.react-datepicker__day {
  @apply !m-0 !h-10 !w-10 !rounded-full font-default text-base !leading-10 !text-info-800;
}

.react-datepicker__day:hover:not(
    .react-datepicker__day--selected,
    .react-datepicker__day--disabled
  ) {
  @apply !bg-info-90;
}

.react-datepicker__day--selected {
  @apply !bg-primary-90 !font-bold !text-primary;
}

.react-datepicker__day--today:not(.react-datepicker__day--selected) {
  @apply !font-normal;
}

.react-datepicker__day--disabled {
  @apply !text-info-300;
}

.react-datepicker__week {
  @apply pb-1;
}

.react-datepicker__day--outside-month {
  @apply !invisible;
}

.react-datepicker__month {
  @apply !m-0 px-3 pb-3;
}
